/* stylelint-disable custom-property-empty-line-before, color-hex-case */

:root {
  --size--x1: 0.25rem;
  --size--x2: 0.5rem;
  --size--x3: 0.75rem;
  --size--x4: 1rem;
  --size--x5: 1.75rem;
  --size--x6: 1.5rem;
  --size--x8: 2rem;
  --size--x10: 2.5rem;
  --size--x12: 3rem;
  --size--x16: 4rem;
  --size--x20: 5rem;

  --border-size--x1: 0.0625rem;
  --border-size--x2: 0.125rem;
  --border-size--x3: 0.1875rem;

  --transition-time--fast: 100ms;
  --transition-time--base: 200ms;
  --transition-time--slow: 400ms;
  --transition-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);

  --color-light: #FFF;
  --color-light-gray: #F6F6F9;
  --color-gray: #E3E3E6;
  --color-dark-gray: #26222C;
  --color-dark: #1D1726;
  --color-blue-light: #8970ff;
  --color-blue: #775eff;
  --color-blue-dark: #6047ff;
  --color-red: #ff4258;

  --color-light-rgb: 255, 255, 255;
  --color-light-gray-rgb: 242, 242, 242;
  --color-dark-gray-rgb: 38, 38, 38;
  --color-dark-rgb: 38, 26, 23;
  --color-blue-light-rgb: 136, 112, 255;
  --color-blue-rgb: 118, 94, 255;
  --color-blue-dark-rgb: 96, 71, 255;
  --color-red-rgb: 255, 66, 88;

  --text-family-ui: 'Roboto', sans-serif;
  --text-family-article: 'Merriweather', serif;

  --text-size--x1: 0.6875rem; /* 12px */
  --text-size--x2: 0.875rem; /* 14px */
  --text-size--x3: 1rem; /* 16px */
  --text-size--x4: 1.25rem; /* 20px */
  --text-size--x5: 1.5rem; /* 24px */

  --text-height--x1: 1.1666666667; /* 16 */
  --text-height--x2: 1.1428571429; /* 16 */
  --text-height--x3: 1.5; /* 24 */
  --text-height--x4: 1.2; /* 24 */
  --text-height--x5: 1.1666666667; /* 28 */

  --text-letter-spacing--x1: 0.5px;
  --text-letter-spacing--x2: 0.25px;
  --text-letter-spacing--x3: -0.2px;
  --text-letter-spacing--x4: -0.2px;
  --text-letter-spacing--x5: -0.3px;

  --text-weight--weak: 400;
  --text-weight--strong: 600;
}

.Button {
  border-width: var(--border-size--x1);
  border-style: solid;
  border-radius: var(--size--x1);
  border-color: transparent;
  background-color: transparent;
  color: inherit;
  font-weight: var(--text-weight--strong);
  line-height: 1;
  transition-property: border-color, background-color, color, opacity;
  transition-duration: var(--transition-time--fast);
  transition-timing-function: var(--transition-timing-function);

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 0;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active,
  &[disabled].Button--active {
    opacity: 0.4;
    cursor: default;
  }

  &::before,
  &::after {
    content: '';
    flex: 1 0 auto;
  }
}

.Button--full-width { width: 100%; }

.Button--size-small {
  padding: var(--size--x2) var(--size--x3);
  font-size: var(--text-size--x2);
}

.Button--size-medium {
  padding: var(--size--x3) var(--size--x4);
  font-size: var(--text-size--x2);
}

.Button--size-large {
  padding: var(--size--x4) var(--size--x5);
  font-size: var(--text-size--x2);
}

.Button--level-primary.Button--color-blue {
  border-color: var(--color-blue);
  background-color: var(--color-blue);
  color: var(--color-light);

  &:hover:not(:disabled) { background-color: rgba(var(--color-blue-rgb), 0.9); }
  &:active:not(:disabled) { background-color: var(--color-blue); }
}

.Button--level-primary.Button--color-light {
  border-color: var(--color-light-gray);
  background-color: var(--color-light-gray);
  color: var(--color-dark-gray);

  &:hover:not(:disabled) { background-color: rgba(var(--color-light-gray-rgb), 0.5); }
  &:active:not(:disabled) { background-color: var(--color-light-gray); }
}

.Button--level-primary.Button--color-red {
  border-color: var(--color-red);
  background-color: var(--color-red);
  color: var(--color-light);

  &:hover:not(:disabled) { background-color: rgba(var(--color-red-rgb), 0.9); }
  &:active:not(:disabled) { background-color: var(--color-red); }
}

.Button--level-secondary.Button--color-blue {
  border-color: var(--color-blue-dark);
  color: var(--color-blue-dark);

  &:hover:not(:disabled) { background-color: rgba(var(--color-blue-dark-rgb), 0.1); }
  &:active:not(:disabled) { background-color: rgba(var(--color-blue-dark-rgb), 0.15); }
}

.Button--level-secondary.Button--color-light {
  border-color: var(--color-light-gray);
  color: var(--color-dark-gray);

  &:hover:not(:disabled) { background-color: rgba(var(--color-light-gray-rgb), 0.5); }
  &:active:not(:disabled) { background-color: rgba(var(--color-light-gray-rgb), 0.75); }
}

.Button--level-secondary.Button--color-red {
  border-color: var(--color-red);
  color: var(--color-red);

  &:hover:not(:disabled) { background-color: rgba(var(--color-red-rgb), 0.1); }
  &:active:not(:disabled) { background-color: rgba(var(--color-red-rgb), 0.15); }
}

.Button--level-tertiary.Button--color-blue {
  color: var(--color-blue-dark);

  &:hover:not(:disabled) { background-color: rgba(var(--color-blue-rgb), 0.1); }
  &:active:not(:disabled) { background-color: rgba(var(--color-blue-rgb), 0.15); }
}

.Button--level-tertiary.Button--color-light {
  color: var(--color-dark-gray);

  &:hover:not(:disabled) { background-color: rgba(var(--color-light-gray-rgb), 0.5); }
  &:active:not(:disabled) { background-color: rgba(var(--color-light-gray-rgb), 0.75); }
}

.Button--level-tertiary.Button--color-red {
  color: var(--color-red);

  &:hover:not(:disabled) { background-color: rgba(var(--color-red-rgb), 0.1); }
  &:active:not(:disabled) { background-color: rgba(var(--color-red-rgb), 0.15); }
}

.List {
  font-weight: var(--text-weight--strong);
}

.List--numbered {
  list-style-type: decimal;
}

.List__item-content {
  font-weight: normal;
}

.Link {
  color: inherit;
  transition-property: color;
  transition-duration: var(--transition-duration--fast);
  transition-timing-function: var(--transition-timing-function);
}

.Link--underline {
  text-decoration: underline;
}

.Link--text-color-blue {
  color: var(--color-blue-dark);

  &:hover { color: var(--color-blue-light); }
  &:active { color: var(--color-blue-light); }
}

.Link--text-color-light {
  color: var(--color-light);

  &:hover { color: var(--color-light-gray); }
  &:active { color: var(--color-light-gray); }
}

.TextInput {
  transition-property: border-color;
  transition-duration: var(--transition-time--fast);
  transition-timing-function: var(--transition-timing-function);
}

.TextInput:focus-within {
  border-color: var(--color-blue);
}

.TextInput__input {
  display: block;
  width: 100%;
  min-width: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  text-overflow: ellipsis;
  line-height: 1;
  appearance: none;

  &::placeholder {
    opacity: 0.2;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.4;
  }
}

.TextInput--size-small .TextInput__input {
  padding: var(--size--x2) var(--size--x3);
  font-size: var(--text-size--x2);
}

.TextInput--size-medium .TextInput__input {
  padding: var(--size--x3) var(--size--x4);
  font-size: var(--text-size--x2);
}

.TextInput--size-large .TextInput__input {
  padding: var(--size--x4) var(--size--x5);
  font-size: var(--text-size--x2);
}
